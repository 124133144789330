import React, { useState } from "react";
import { Tenants } from "./components/Tenants";
import { Contexts } from "./components/Contexts";
import { Pusher } from "./components/Pusher";
import { Messages } from "./components/Messages";
import { Workspace } from "./components/Workspace";
import { Login } from "./components/Login";
import "./App.scss";


const App = () => {
    const [activeTenant, _setActiveTenant] = useState(0);
    const [activeContexts, setActiveContexts] = useState([]);
    const [messages, setMessages] = useState([]);

    // Toggles a context in the
    // list of active contexts
    const toggleContext = id => {
        if (activeContexts.includes(id)) {
            setActiveContexts(activeContexts.filter(_id => _id !== id));
            return;
        }
        setActiveContexts([ ...activeContexts, id ]);
    }

    // Delegator to set the active tenant,
    // which additionally resets the active
    // contexts, if the active tenant changes
    const setActiveTenant = id => {
        if (activeTenant !== id) setActiveContexts([]);
        _setActiveTenant(id);
    }

    // Pushes a message into
    // the list of messages
    const pushMessage = message => {
        setMessages(messages =>  messages ? [ message, ...messages ] : [ message ]);
    }

    return hasSession() ? <>
        <Tenants setActiveTenant={setActiveTenant} activeTenant={activeTenant} />

        {activeTenant && <Contexts toggleContext={toggleContext}
            activeTenant={activeTenant} activeContexts={activeContexts} />}

        <Workspace active={activeContexts.length !== 0}>
            <Pusher activeContexts={activeContexts}
                pushMessage={pushMessage} />
            <Messages activeContexts={activeContexts}
                messages={messages} setMessages={setMessages} />
        </Workspace>
    </> : <Login />;
}

export default App;


/**
 * Check if the HTTP only session cookie exists by
 * setting a value for the same cookie with JavaScript;
 */
const hasSession = () => {
    var d = new Date();
    d.setTime(d.getTime() + (1000));

    document.cookie = `_webpush_session=test;path=/;expires=${d.toUTCString()}`;
    return document.cookie.indexOf("_webpush_session=") === -1;
}
