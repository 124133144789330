export class Client {
    static endpoint = "/api/v1";
    static credentials = "include";
    static headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    };

    static fetch = async (input, init) => {
        const response = await fetch(input, init);
        if (response.status === 401) {
            window.location.reload();
        } else {
            return response;
        }
    }

    static async POST(path, body) {
        return await this.fetch(`${this.endpoint}${path}`, {
            method: "POST",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(body)
        })
    }

    static async GET(path) {
        return await this.fetch(`${this.endpoint}${path}`, {
            method: "GET",
            credentials: this.credentials,
            headers: this.headers
        })
    }

    static async PATCH(path, body) {
        return await this.fetch(`${this.endpoint}${path}`, {
            method: "PATCH",
            credentials: this.credentials,
            headers: this.headers,
            body: JSON.stringify(body)
        })
    }

    static async DELETE(path) {
        return await this.fetch(`${this.endpoint}${path}`, {
            method: "DELETE",
            credentials: this.credentials,
            headers: this.headers
        })
    }
}
