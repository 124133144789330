import React from "react";
import { Client } from "../Client";
import { useForm } from "react-hook-form";


export const ContextForm = ({ tenant, addContext }) => {
    const { register, handleSubmit, reset } = useForm({
        defaultValues: {
            name: ""
        }
    });

    const onSubmit = async data => {
        const response = await Client.POST("/contexts", {
            _tenant: tenant, ...data
        });

        if (response.status < 300) {
            const newContext = await response.json();
            addContext(newContext);
            reset();
        }
    }

    return <div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <input name="name" type="text"
                placeholder="Name"
                ref={register({
                    required: true,
                    validate: value => value.length >= 3 && value.length <= 100
                })}
            />
            <button>Add</button>
        </form>
    </div>
}
