import React, { useRef, useState } from "react";
import styles from "./Pusher.module.scss";
import { Header } from "../elements/Header";
import { Client } from "../Client";
import { useForm } from "react-hook-form";


export const Pusher = ({ activeContexts, pushMessage }) => {
    const [ locked, setLocked ] = useState(false);
    const { register, handleSubmit, reset } = useForm({
        defaultValues: {
            title: "",
            body: "",
            badge: "",
            icon: "",
            image: "",
            topic: ""
        }
    });

    const form = useRef();

    const onSubmit = async data => {
        if (!locked) {
            setLocked(true);
            const response = await Client.POST(
                `/contexts/${activeContexts.join(",")}/messages`, data);

            if (response.status < 300) {
                const message = await response.json();
                pushMessage(message);
                reset();
            }
            setLocked(false);
        }
    }

    return <section className={styles.pusher}>
        <Header title="Push a message" />

        <form onSubmit={handleSubmit(onSubmit)} ref={form}>
            <input name="title" type="text"
                placeholder="Title (3 - 150 characters)"
                ref={register({
                    required: true,
                    validate: value => value.length >= 3 && value.length <= 150
                })}
            />
            <input name="badge" type="text"
                placeholder="Badge-URL (optional)"
                ref={register({ required: false })}
            />
            <input name="icon" type="text"
                placeholder="Icon-URL (optional)"
                ref={register({ required: false })}
            />
            <input name="image" type="text"
                placeholder="Image-URL (optional)"
                ref={register({ required: false })}
            />
            <textarea name="body"
                placeholder="Message (20 - 255 characters)"
                ref={register({
                    required: true,
                    validate: value => value.length >= 20 && value.length <= 255
                })}
            ></textarea>

            <input name="topic" type="hidden" ref={register} />
            <button type="submit" onClick={e => e.preventDefault()}
                onDoubleClick={() => {
                    if (form.current) {
                        form.current.dispatchEvent(new Event('submit'));
                    }
                }}
            >Push message (Double click)</button>
        </form>
    </section>
}
