import React, { useEffect } from "react";
import styles from "./Messages.module.scss";
import { Header } from "../elements/Header";
import { Client } from "../Client";


export const Messages = ({ activeContexts, messages, setMessages }) => {
    useEffect(() => {
        (async () => {
            if (activeContexts.length) {
                const response = await Client.GET(
                    `/contexts/${activeContexts.join(",")}/messages`);

                if (response.status < 300) {
                    const messages = await response.json();
                    setMessages(messages);
                }
            }
        })()
    }, [activeContexts, setMessages]);

    useEffect(() => {}, [messages]);

    return <section className={styles.messages}>
        <Header title="Recently sent messages" />

        <table>
            <thead>
                <tr>
                    <th>Title</th>
                    <th>Body</th>
                    <th>Pushed at</th>
                </tr>
            </thead>
            <tbody>
                {messages?.map(message => (
                    <tr key={message._id} className={styles.message}>
                        <td>{message.title}</td>
                        <td>{message.body}</td>
                        <td>{new Date(message.pushedAt*1000).toLocaleString()}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </section>
}
