import React from "react";
import styles from "./Workspace.module.scss";


export const Workspace = ({ active, children }) => (
    <main className={styles.workspace} data-active={active}>
        {active ? children : <p>
            Please select a tenant and one or more contexts <br/>
            to push a message and to show recently sent messages
        </p>}
    </main>
)
