import React, { useState, useEffect } from "react";
import { ContextForm } from "./ContextForm";
import { Header } from "../elements/Header";
import styles from "./Tenants.module.scss";
import { Client } from "../Client";


export const Contexts = ({ activeTenant, toggleContext, activeContexts }) => {
    const [contexts, setContexts] = useState([]);

    useEffect(() => {
        (async () => {
            const response = await Client.GET(`/tenants/${activeTenant}`);

            if (response.status < 300) {
                const { contexts } = await response.json();
                setContexts(contexts);
            }
        })()
    }, [activeTenant]);

    const addContext = newCcontext => {
        setContexts(contexts =>  contexts ? [ ...contexts, newCcontext ] : [ newCcontext ]);
    }

    return <aside className={styles.contexts}>
        <Header title="Contexts" />

        {contexts?.length ? <>
            <ul>
                {contexts.map(context => (
                    <li key={context._id}
                        onClick={() => toggleContext(context._id)}
                        className={activeContexts.includes(context._id) ? styles.active : null}
                    >
                        {context.name}
                    </li>
                ))}
            </ul>
        </> : (
            <div className={styles.noresults}>No contexts available</div>
        )}

        <ContextForm tenant={activeTenant} addContext={addContext} />
    </aside>
}
