import React, { useState, useEffect } from "react";
import { TenantForm } from "./TenantForm";
import { Header } from "../elements/Header";
import styles from "./Tenants.module.scss";
import { Client } from "../Client";


export const Tenants = ({ setActiveTenant, activeTenant }) => {
    const [tenants, setTenants] = useState([]);

    useEffect(() => {
        (async () => {
            const response = await Client.GET("/tenants");

            if (response.status < 300) {
                const tenants = await response.json();
                setTenants(tenants);
            }
        })()
    }, []);

    const addTenant = tenant => {
        setTenants(tenants =>  tenants ? [ ...tenants, tenant ] : [ tenant ]);
    }

    return <aside className={styles.tenants}>
        <Header title="Tenants" />

        <ul>
            {tenants?.map(tenant => (
                <li key={tenant._id}
                    onClick={() => setActiveTenant(tenant._id)}
                    className={tenant._id === activeTenant ? styles.active : null}
                >
                    {tenant.name}
                </li>
            ))}
        </ul>

        <TenantForm addTenant={addTenant} />
    </aside>
}
