import React, { useState } from "react";
import { Client } from "../Client";
import styles from "./Login.module.scss";

export const Login = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const handleSubmit = async e => {
        e.preventDefault();

        const response = await Client.POST("/auth/aquire", {
            username: username,
            password: password
        });

        if (response.status === 202) {
            window.location.reload();
        } else {
            setUsername("");
            setPassword("");
        }
    }

    return <div className={styles.login}>
        <form onSubmit={handleSubmit}>
            <input name="username" type="text"
                onChange={e => setUsername(e.target.value)}
                placeholder="Username"
            />
            <input name="password" type="password"
                onChange={e => setPassword(e.target.value)}
                placeholder="Password"
            />
            <button>Login</button>
        </form>
    </div>
}